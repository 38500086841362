.login-page-pss {
  background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.934),
      rgba(255, 255, 255, 0.911)
    ),
    url("https://images.unsplash.com/photo-1531973576160-7125cd663d86?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.page-content {
  display: flex;
  justify-content: center;
  text-align: center;

  .MuiCardContent-root {
    padding: 0px;
    &:last-child {
      padding-bottom: 0px;
    }
  }

  .login-card {
    padding: 0px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 100px;
    margin-left: 16px;
    margin-right: 16px;
    min-width: 320px;
    min-height: 400px;

    img {
      @media (max-width: 768px) {
        height: 200px;
      }
    }

    .content {
      padding-top: 140px;
      justify-content: center;
      text-align: start;

      @media (max-width: 768px) {
        padding-top: 10px;
        padding-left: 30px;
      }

      h3 {
        font-size: 26px;
        font-weight: 500;
      }
    }

    .button-div {
      margin-top: 20px;

      .google-button {
        border-radius: 20px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}
