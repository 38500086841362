.pss-footer {
  #main-footer {
    margin-top: 40px;
    padding-top: 50px;
    padding-bottom: 30px;
    img {
      max-width: 220px;
    }
    border-top: 2px solid #0171a9;
    .social-icons {
      margin-top: 25px;
      padding-left: 30px;
      i {
        font-size: 18px;
      }
    }
    h4 {
      @media (max-width: 768px) {
        margin-top: 30px;
      }
    }
    .other-links {
      h4 {
        color: #0171a9;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 600;
      }
      .link {
        margin-bottom: 10px;
        color: #3d3d3d;
        a {
          color: #3d3d3d;
          &:hover {
            color: #0171a9;
          }
        }
      }
      .contact-us-point {
        color: #3d3d3d;
      }
    }
    .about-us {
      h4 {
        color: #0171a9;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 10px;
      }
      margin-top: 30px;
      color: rgb(95, 95, 95);
    }

    .copyright {
      padding: 6px 0px 6px 0px;
      background-color: #0171a9;
      margin-top: 40px;
      text-align: center;
      color: #ffffff;

      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
}
