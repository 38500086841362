#home--upcoming-features{
    .feature-item{
        margin: 15px 0;
        box-shadow: 5px 5px 10px 5px rgba(238, 238, 238, .62);
        .image{
            img{
                width: 100%;
            }
        }
        .content{
            padding: 20px;
            h4{
                font-size: 18px;
                font-weight: 600;
                color: #005e71;
            }
            p{
                font-size: 14px;
            }
        }
    }
}