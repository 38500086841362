#add-ons-page {
  .add-on-item {
    background-color: rgb(255, 255, 255);
    .cover-div {
      overflow: hidden;
      height: 200px;
      margin-bottom: 40px;
      object-fit: cover;
    }
    .card-cover-img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: 0.2s ease-in-out;
    }
    .add-on-item-content {
      position: relative;
      min-height: 130px;

      .icon-div {
        position: absolute;
        top: -80px;
        left: 10px;
        background-color: white;
        padding: 5px;
        border-radius: 50%;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.185);

        // border: 1px solid grey;
        .icon {
          object-fit: cover;
          border-radius: 50%;
          height: 60px;
          width: 60px;
        }
      }
    }

    &:hover {
      .card-cover-img {
        scale: 1.1;
        transition: 0.2s ease-in-out;
      }
      box-shadow: 0px 0px 30px rgb(225, 225, 225);
    }
  }
}

#add-ons-review-details {
  .ad-on-cover-banner {
    height: 200px;
    width: 100%;
    object-fit: cover;
    margin-bottom: 60px;
    background-color: white;
  }

  .purchase-amount-content {
    margin-top: 50px;
    background-color: white;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 6px;
    padding: 15px;
    box-shadow: 0px 0px 10px rgb(206, 206, 206);

    .includes-items {
      .item {
        border: 1px solid rgb(230, 230, 230);
        border-radius: 10px;
      }
    }
  }
  .add-on-item-content {
    position: relative;
    min-height: 150px;

    .icon-div {
      position: absolute;
      top: -170px;
      left: 0px;
      background-color: white;
      padding: 5px;
      border-radius: 50%;
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.185);

      // border: 1px solid grey;
      .icon {
        object-fit: cover;
        border-radius: 50%;
        height: 120px;
        width: 120px;
      }
    }
  }
}
