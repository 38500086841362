.admin-login-page-pss {
  background-image: linear-gradient(
      45deg,
      rgba(0, 84, 162, 0.934),
      rgba(0, 149, 218, 0.911)
    ),
    url("https://images.unsplash.com/photo-1534665482403-a909d0d97c67?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80");
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;

  .admin-form-box {
    background-color: #ffffff;
    padding: 30px;
    margin-top: 200px;
  }

  .MuiOutlinedInput-root {
    border: none;
  }
  .MuiOutlinedInput-input {
    background-color: #eaeaea;
  }
}
