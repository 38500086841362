.payment-form-box {
  background-color: #ffffff;
  border-radius: 0px;
  // border: 1px solid #e7e7e7;
  padding: 20px;
  // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.pre-payment-form {
  .plan-details {
    .more-details {
      display: none;
    }
  }

  @media (max-width: 768px) {
    .plan-details {
      .more-details {
        display: block;
      }
    }
    .hide-details {
      display: none;
    }
  }
}
// .main-box {
//   margin-left: 250px;
//   margin-right: 250px;
//   @media (max-width: 992px) {
//     margin-left: 0px;
//     margin-right: 0px;
//   }
// }

.product-details-mockup {
  justify-content: center;
  display: flex;

  .item {
    text-align: left;
    padding: 0px 10px 0px 10px;
  }

  td {
    border-style: none;
    padding: 5px 5px 5px 5px;
  }

  @media (max-width: 1300px) {
    display: block;
  }
  border-radius: 0px;
  color: #0171a9;
  .sub-head {
    color: #4b4b4b;
  }
}

#additionalPurchase {
  .addonImage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    margin: 5px 0;
    img {
      height: auto;
      width: 100%;
    }
  }
  .addon-item:hover {
    background-color: #0171a911;
    cursor: pointer;
  }
  .purchase-summary-sm {
    display: none;
  }
  position: relative;
  @media (max-width: 767px) {
    font-size: 10px;
    .purchase-summary {
      display: none;
    }
    .purchase-summary-sm {
      background: white;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      bottom: 0;
    }
  }
}
