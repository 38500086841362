.select-header-footer-ps {
  .select-option {
    transform: scale(0.92);
    margin: 20px 0;
    border-radius: 10px;
    border: 4px solid rgb(170, 170, 170);
    transition: 0.1s ease-in-out;
    position: relative;

    .select-option-label {
      position: absolute;
      top: -24px;
      left: -4px;
      border-radius: 4px 4px 0 0;
      color: white;
      padding: 3px 8px;
      background-color: rgb(170, 170, 170);
    }
  }
  .select-option:hover {
    transition: 0.1s ease-in-out;
    cursor: pointer;
  }
  .selected {
    border-radius: 10px;
    transform: scale(1);
    border: 4px solid rgb(99, 186, 99);
    box-shadow: rgb(156, 198, 156) 0px 0px 20px;
    .select-option-label {
      background-color: rgb(99, 186, 99);
      color: white;
    }
  }
}

.ps-banners {
  .banners-preview {
    // border: 1px solid black;
    width: 100%;
    min-height: 250px;
    margin: 20px 0;

    .banners-preview-item-container {
      height: 300px;
      width: 250px;
    }

    .banners-preview-item {
      cursor: pointer;
      position: relative;
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: center;
      background-size: cover;
      background: radial-gradient(#ffffff, #d2d2d2);
      // padding: 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      height: 250px;
      width: 250px;
      border: 2px solid rgb(199, 199, 199);
      // border-radius: 5px;

      z-index: 1;
      .banner-image {
        z-index: 2;
        position: absolute;
        height: 100%;
        width: 100%;
        img {
          height: auto;
          width: 100%;
        }
      }
      span {
        text-decoration: underline;
        cursor: pointer;
      }
      &:hover {
        .banner-content {
          opacity: 1;
          height: 85%;
          width: 90%;
          transition: 0.3s ease-in-out;
        }
      }
      .banner-content {
        z-index: 3;
        position: absolute;
        padding: 20px;
        color: white;
        transition: 0.3s ease-in-out;
        opacity: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.375);
      }
    }
  }
}
