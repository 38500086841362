.siteHeader {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;
  padding: 5px;
  align-items: stretch;
  justify-content: center;
  box-shadow: 3px 3px 5px 3px #ddd;
  background: #fff !important;
  z-index: 200;
  margin-bottom: 2rem;
}
.siteHeader img {
  width: 10em;
}

.childSiteHeader {
  flex: 1 0 auto;
}
.childSiteHeader img {
  width: 200px;
  max-width: 70vw;
}

.pointer {
  cursor: pointer;
}

.childSiteHeaderRight {
  text-align: right;
  color: rgb(41, 38, 38);
  height: 100%;
  align-self: center;
}

.spacer100 {
  height: 100px;
  display: block;
}

.spacer50 {
  height: 50px;
  display: block;
}

.btnDark {
  font-weight: bolder;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  color: white;
  background-color: rgb(41, 38, 38);
  box-shadow: 0 0 4px #999;
  outline: none;
  transition: background 0.8s;
}
.btnDark:hover {
  background: #363232 radial-gradient(circle, transparent 1%, #363232 1%) center/15000%;
}
.btnDark:active {
  background-color: #292626;
  background-size: 100%;
  transition: background 0s;
}

.btnRed {
  font-weight: bolder;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  color: white;
  background-color: #db2828;
  box-shadow: 0 0 4px #999;
  outline: none;
  transition: background 0.8s;
}
.btnRed:hover {
  background: #df3e3e radial-gradient(circle, transparent 1%, #df3e3e 1%) center/15000%;
}
.btnRed:active {
  background-color: #dc2727;
  background-size: 100%;
  transition: background 0s;
}

.btnTeal {
  font-weight: bolder;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  color: white;
  background-color: #00b5ad;
  box-shadow: 0 0 4px #999;
  outline: none;
  transition: background 0.8s;
}
.btnTeal:hover {
  background: #00cfc5 radial-gradient(circle, transparent 1%, #00cfc5 1%) center/15000%;
}
.btnTeal:active {
  background-color: #00b5ad;
  background-size: 100%;
  transition: background 0s;
}

.btnBlue {
  font-weight: bolder;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  color: white;
  background-color: #2185d0;
  box-shadow: 0 0 4px #999;
  outline: none;
  transition: background 0.8s;
}
.btnBlue:hover {
  background: #2d92de radial-gradient(circle, transparent 1%, #2d92de 1%) center/15000%;
}
.btnBlue:active {
  background-color: #2085d1;
  background-size: 100%;
  transition: background 0s;
}

.pillDark {
  background-color: rgb(41, 38, 38);
  color: #fff;
  padding: 0.1rem 0.5rem;
  margin: 0.5rem;
  font-weight: bolder;
  border-radius: 0.5rem;
}

.pillTeal {
  background-color: #00b5ad;
  color: #fff;
  padding: 0.1rem 0.5rem;
  margin: 0.5rem;
  font-weight: bolder;
  border-radius: 0.5rem;
}

.card {
  border-radius: 5px;
  padding: 1em !important;
  margin: 1.5em !important;
  padding: 1em;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
  overflow: hidden;
}

.grid200 {
  display: grid;
  gap: 0.5rem;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(200px, 400px));
}

.grid250 {
  display: grid;
  gap: 0.5rem;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 500px));
}

.grid300 {
  display: grid;
  gap: 0.5rem;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 500px));
}

.grid400 {
  display: grid;
  gap: 0.5rem;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(400px, 700px));
}

img {
  width: 100%;
}

a {
  transition-property: color;
  transition-duration: 1s;
}

.bgTeal {
  background-color: #00b5ad;
  color: #fff;
}

.bold {
  font-weight: bolder;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.p1 {
  padding: 1rem;
}

.properCenter {
  display: felx;
  justify-content: center;
  align-self: center;
}

.slick-prev {
  left: 3% !important;
  z-index: 1;
}

.slick-next {
  right: 3% !important;
  z-index: 1;
}

.draggable-list-item {
  background-color: #fff;
  border: 1px solid rgba(198, 198, 198, 0.431);
  padding: 10px 15px;
  margin: 11px 0;
  box-shadow: 5px 5px 8px 2px #eee;
}

.fixed-bottom-center {
  position: fixed;
  left: 50%;
  bottom: 15px;
  transform: translate(-50%, 0);
  z-index: 999;
}

.modal {
  z-index: 1500;
}/*# sourceMappingURL=common.css.map */