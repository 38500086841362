.pss-header {
  .navbar-space {
    height: 80px;
  }
  #header {
    padding: 16px 0px 16px 0px;
    .sippi-logo {
      img {
        max-width: 220px;

        @media (max-width: 375px) {
          max-width: 200px;
        }
      }
    }

    .login-button {
      .btn-primary {
        background-color: #0171a9;
        color: #ffffff;
        height: 34px;

        &:hover {
          background-color: #ffffff;
          color: #0171a9;
        }
      }
    }

    .login-person {
      img {
        margin: 10px;
      }
      @media (max-width: 768px) {
        img {
          margin: 0px;
        }
        p {
          display: none;
        }
      }
    }
    .menu-button {
      border: 1px solid #0171a9;
      color: #0171a9;

      width: 80px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      &:hover {
        background-color: #0171a9;
        color: #ffffff;
      }

      i {
        padding-right: 10px;
      }

      @media (max-width: 768px) {
        width: 35px;

        i {
          padding-right: 0px;
        }
      }

      .menu-btn-text {
        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    .logout-button {
      margin-left: 20px;
      margin-right: 20px;
    }

    .btn-primary {
      color: #0171a9;
      border: 1px solid #0171a9;
      background-color: #ffffff;
      margin-right: 20px;

      &:hover {
        background-color: #0171a9;
        border: 1px solid #0171a9;
        color: #ffffff;
      }
    }
  }
}
