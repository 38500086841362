#partner-sippi-front {
  background-image: url("../../../../assets/images/newHome/partner-sippi/front.png");
  background-color: #5d5d5d;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1180px) {
    background-image: url("../../../../assets/images/newHome/partner-sippi/front-tab-img.png");
  }

  @media (max-width: 768px) {
    background-image: url("../../../../assets/images/newHome/partner-sippi/front-tab-img.png");
  }

  @media (max-width: 575.98px) {
    background-image: url("../../../../assets/images/newHome/partner-sippi/front-mb-img.png");
  }

  .content {
    padding-left: 200px;
    padding-right: 200px;

    @media (max-width: 768px) {
      padding-left: 10px;
      padding-right: 10px;
    }

    @media (max-width: 575.98px) {
      padding-left: 10px;
      padding-right: 10px;
      text-align: start;
    }
  }

  h1 {
    text-align: center;
    font-size: 52px;
    font-weight: 700;
    color: #5d5d5d;

    @media (max-width: 768px) {
      font-size: 32px;
    }

    @media (max-width: 575.98px) {
      text-align: start;
      font-size: 32px;
    }
  }

  h1 span {
    font-size: 116px;
    background: linear-gradient(95.93deg, #0070a9 2.98%, #74d2ee 99.6%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    display: inline-block;

    @media (max-width: 992px) {
      font-size: 66px;
    }

    @media (max-width: 768px) {
      font-size: 72px;
    }

    @media (max-width: 575.98px) {
      text-align: start;
      font-size: 58px;
    }
  }

  img {
    @media (max-width: 575px) {
      padding-bottom: 40px;
    }
  }
}

.section-padding {
  padding: 60px 0;
}

#partner-sippi-front {
  .button {
    text-align: center;
    margin-top: 20px;

    @media (max-width: 575px) {
      text-align: start;
    }
  }
}

.pricing {
  margin-top: 100px;
  background-image: linear-gradient(to right, #0084b0f5, #006176f6),
    url("../../../../assets/images/newHome/partner-sippi/pricing-bg.png");
  padding: 40px 0px 40px 0px;
  background-size: cover;
  h1 {
    margin-left: 30px;
    color: #ffffff;
    font-size: 50px;
    font-weight: 600;
  }
  // .box {
  //   border: 1px solid #ffffff;
  //   padding: 20px;
  // }

  .price-img {
    width: 100%;
  }

  .price-drop {
    width: 180px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  .priceheading {
    // background-color: #ffffffd3;

    //   .row {
    //     align-items: center;
    //   }
    // .offer-point {
    //   span {
    //     font-size: 15px;
    //     border-radius: 20px;
    //     background-color: rgb(255, 217, 0);
    //     color: #ffffff;
    //     // padding: 8px 14px 8px 14px;
    //   }
    // }
    .cutting-price {
      color: rgb(255, 217, 0);
      font-size: 60px;
      padding-left: 20px;
      text-decoration: line-through #ffffff;
      @media (max-width: 1288px) {
        font-size: 45px;
      }
      @media (max-width: 1200px) {
        font-size: 40px;
      }
      @media (max-width: 992px) {
        font-size: 30px;
      }
      @media (max-width: 768px) {
        font-size: 45px;
      }
      @media (max-width: 488px) {
        font-size: 30px;
      }
    }
    .for-just {
      font-size: 60px;
      // padding-right: 20px;
      @media (max-width: 1288px) {
        font-size: 45px;
      }
      @media (max-width: 1200px) {
        font-size: 40px;
      }
      @media (max-width: 992px) {
        font-size: 30px;
      }
      @media (max-width: 768px) {
        font-size: 45px;
      }
      @media (max-width: 488px) {
        font-size: 30px;
      }
    }
    .price-div {
      margin-top: 15px;
    }
    .actual-price {
      background-color: #ffffff;
      padding: 10px 20px 10px 20px;
      color: #0070a9;
      font-size: 60px;
      @media (max-width: 1288px) {
        font-size: 45px;
      }
      @media (max-width: 1200px) {
        font-size: 40px;
      }
      @media (max-width: 992px) {
        font-size: 30px;
      }
      @media (max-width: 768px) {
        font-size: 45px;
      }
      @media (max-width: 488px) {
        font-size: 30px;
      }
    }
    .portal {
      font-size: 60px;
      @media (max-width: 1288px) {
        font-size: 45px;
      }
      @media (max-width: 1200px) {
        font-size: 40px;
      }
      @media (max-width: 992px) {
        font-size: 30px;
      }
      @media (max-width: 768px) {
        font-size: 45px;
      }
      @media (max-width: 488px) {
        font-size: 30px;
      }
    }
  }
}

#e-shop--lite--feature {
  background: rgba(124, 124, 124, 0.05);
  border: 1px solid rgba(14, 143, 209, 0.25);
  img {
    width: 50px;
  }

  .item {
    @media (max-width: 768px) {
      padding-bottom: 20px;
    }
  }
  .container h4 {
    margin-bottom: 6px;
    font-size: 20px;
    font-weight: 600;
    color: #0e8fd1;
  }

  p {
    font-size: 15px;
  }
}

#features-points {
  background-image: url("../../../../assets/images/newHome/partner-sippi/features-bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  // height: 900px;

  @media (max-width: 768px) {
    background-image: url("../../../../assets/images/newHome/partner-sippi/feature-tab-bg.png");
    font-size: 16px;
  }

  @media (max-width: 575.98px) {
    background-image: url("../../../../assets/images/newHome/partner-sippi/features-mobile-bg.png");
    background-size: 100%;
  }

  .heading {
    p {
      font-size: 15px;
      font-weight: 400;
    }
  }

  p {
    font-size: 16px;

    @media (max-width: 768px) {
      font-size: 12px;
    }

    @media (max-width: 575.98px) {
      font-size: 12px;
    }
  }

  .inclusion-item {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 30px;
    padding-left: 50px;
    margin-bottom: 20px;
    position: relative;
    min-height: 130px;
    height: calc(100% - 10px);

    p {
      font-size: 15px;
      // position: absolute;
      // top: 50%;
      // transform: translateY(-50%);
      // left: 0;
      // right: 0;
      margin-bottom: 0;
    }

    .arrow-icon {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #fff;
      border: 1px solid #0171a9;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #0171a9;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &.left-arrow .arrow-icon {
      left: -25px;
    }

    &.right-arrow .arrow-icon {
      right: -25px;
    }
  }
}

// #fixed-departured{
//   background-color: #F2FAFF;
// }

#vocal-for-local {
  padding-top: 80px;
  padding-bottom: 80px;
  background-image: linear-gradient(
      45deg,
      rgba(240, 240, 240, 0.841),
      rgba(243, 243, 243, 0.9)
    ),
    url("../../../../assets/images/newHome/partner-sippi/vfl-bg.png");
  border: 1px solid #d3d3d3;

  .content {
    text-align: center;

    img {
      width: 50%;

      @media (max-width: 992px) {
        width: 100%;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  h1 {
    font-size: 72px;
    display: flex;
    text-align: center;
    justify-content: center;
    font-weight: 700;
    .vocal {
      background: linear-gradient(180deg, #eb921c 0%, #ff1515 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    .for {
      background-color: #ffffff;
      border-radius: 100px;
      padding: 0px 30px 0px 30px;
      margin: 0px 30px 0px 30px;
      color: #0064b9;
    }
    .local {
      background: linear-gradient(180deg, #00db30 0%, #00a223 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    @media (max-width: 992px) {
      font-size: 60px;
    }
    @media (max-width: 768px) {
      font-size: 50px;
    }
    @media (max-width: 700px) {
      font-size: 40px;
    }
    @media (max-width: 625px) {
      display: grid;
      font-size: 60px;
    }
    @media (max-width: 555px) {
      display: grid;
      font-size: 58px;
    }
    @media (max-width: 400px) {
      display: grid;
      font-size: 48px;
    }
  }

  p {
    font-size: 20px;
    color: #5d5d5d;
    padding: 0px 180px 0px 180px;
    @media (max-width: 992px) {
      padding: 0px;
    }
    @media (max-width: 768px) {
      padding: 0px;
    }
  }
}

#sample-work {
  background-image: url("../../../../assets/images/newHome/partner-sippi/sample-bg.png");
  background-repeat: no-repeat;
  background-size: 100%;

  @media (max-width: 575.98px) {
    background-image: url("../../../../assets/images/newHome/partner-sippi/sample-mobile-bg.png");
  }

  img {
    width: 50%;
  }
}

#White-lable--features {
  p {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.53);

    @media (max-width: 768px) {
      font-size: 16px;
    }

    @media (max-width: 575.98px) {
      font-size: 16px;
    }
  }

  .logo {
    transition: transform 0.4s;

    img {
      width: 70%;
    }

    @media (max-width: 768px) {
      padding-bottom: 20px;
      padding-top: 20px;
    }

    @media (max-width: 575.98px) {
      font-size: 16px;
      padding-bottom: 20px;
    }
  }

  .logo:hover {
    transform: scale(1.2);
  }
}

#tech-in-use {
  .row {
    justify-content: space-between;
  }

  .content {
    margin-bottom: 30px;
    .img {
      // width: 100px;
      height: 100px;

      img {
        height: 80px;
        width: auto;
      }
      .ssl {
        height: auto;
      }
    }
  }

  h5 {
    font-size: 14px;
    font-weight: 400;
  }
}

#tag-line {
  h1 {
    @media (max-width: 575px) {
      font-size: 26px;
    }

    @media (max-width: 768px) {
      font-size: 40px;
    }
  }
}

#travel-app {
  p {
    font-size: 16px;
    padding-right: 20px;
  }

  .button-android {
    width: 160px;
  }
}
