.service-charges {
  ul {
    list-style: none;
    padding: 0;
  }
  h1 {
    font-size: 30px;
    @media (max-width: 991.98px) {
      font-size: 24px;
    }
  }
  .labelbar {
    @media (max-width: 767.98px) {
      display: none;
    }
  }
  .mobilelabel {
    @media (min-width: 767.98px) {
      display: none;
    }
  }
}
