.main-heading {
  margin: 30px 0;
  margin-bottom: 50px;

  h2 {
    color: #2c2c2c;
    font-size: 2rem;
    margin-bottom: 30px;
    span {
      font-weight: 700;
      color: #0179a7;
    }

    position: relative;

    &:after {
      content: "";
      width: 90px;
      height: 4px;
      background-color: #0179a7;
      position: absolute;
      bottom: -20px;
      left: 0;
    }
  }

  &.center {
    text-align: center;

    h2 {
      &:after {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

h2 {
  font-weight: 300;
  font-size: 34px;
  line-height: 126.4%;
  position: relative;
}
h2 span {
  color: #0e8fd1;
  font-weight: 600;
  font-size: 34px;
}

body,
h1,
h2,
p,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  line-height: 1.2;
}

#tag-line {
  background: rgba(124, 124, 124, 0.05);
  border: 1px solid rgba(14, 143, 209, 0.25);
  h1 {
    font-size: 52px;
    font-weight: 700;
    background: linear-gradient(95.93deg, #0070a9 2.98%, #74d2ee 99.6%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
}
