
.myinput{
    box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	outline: none;
	display: block;
	width: 100%;
	padding: 7px;
	border: 1px solid #ddd;
	background: transparent;
	margin-bottom: 10px;
	font: 14px Arial, Helvetica, sans-serif;
	height: 40px;
}

.myinput:hover{
    
    background-color:#eee;
    
}

.myerror{
    color:red;
    font-weight: bolder;
}
.mytextarea{
    box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	outline: none;
	display: block;
	width: 100%;
	padding: 7px;
	border: 1px solid #ddd;
	background: transparent;
	margin-bottom: 10px;
	font: 14px Arial, Helvetica, sans-serif;
	
}