.viewWhitelabelComponent .grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}
.viewWhitelabelComponent .card {
  overflow: hidden;
}
.viewWhitelabelComponent .whitelabelBox {
  padding: 1rem;
  font-size: 1.2rem;
  border-radius: 5px;
  margin-bottom: 1rem;
}
.viewWhitelabelComponent .whitelabelBox img {
  background-color: #fff;
  padding: 1em;
  border-radius: 5px;
  max-width: 100%;
}
.viewWhitelabelComponent .whitelabelBox p {
  padding: 0;
  margin: 0rem;
}
.viewWhitelabelComponent .actionBar {
  text-align: right;
  padding: 1rem;
  margin: 1rem;
  border-top: 2px dashed #ddd;
}
.viewWhitelabelComponent .actionBar button {
  font-weight: bolder;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  color: white;
  background-color: #e03997;
  box-shadow: 0 0 4px #999;
  outline: none;
  transition: background 0.8s;
}
.viewWhitelabelComponent .actionBar button:hover {
  background: #e34fa3 radial-gradient(circle, transparent 1%, #e34fa3 1%) center/15000%;
}
.viewWhitelabelComponent .actionBar button:active {
  background-color: #e13897;
  background-size: 100%;
  transition: background 0s;
}

.createWhiteLabelGrid button {
  font-weight: bolder;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  color: white;
  background-color: #00b5ad;
  box-shadow: 0 0 4px #999;
  outline: none;
  transition: background 0.8s;
}
.createWhiteLabelGrid button:hover {
  background: #00cfc5 radial-gradient(circle, transparent 1%, #00cfc5 1%) center/15000%;
}
.createWhiteLabelGrid button:active {
  background-color: #00b5ad;
  background-size: 100%;
  transition: background 0s;
}

.whitelabelTable {
  vertical-align: middle !important;
}
.whitelabelTable .flexDiv {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-flow: column;
  align-self: center;
}
.whitelabelTable .flexDiv2 {
  background-color: green;
  height: 100%;
  display: block;
}/*# sourceMappingURL=viewWhitelabel.css.map */