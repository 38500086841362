.user-wallet {
  min-height: 100vh;
  .user-wallet-heading {
    font-weight: 400;
    font-size: 25px;
  }
  background-color: rgb(244, 244, 244);
  .user-wallet-card {
    h2 {
      font-weight: 500;
    }
    min-height: 250px;
    h3 {
      font-weight: 400;
    }
    p {
      color: rgb(126, 126, 126);
      font-size: 13px;
    }
  }
  .other-costs {
    text-decoration: underline;
    cursor: pointer;
  }
  .remark-sm-label {
    display: none;
  }
  @media (max-width: 767px) {
    .user-wallet-heading {
      font-size: 18px;
      padding: 0;
    }
    .date-filter {
      font-size: 10px;
      padding: 3px;
    }
    .wallet-subheading {
      font-size: 15px;
      padding-left: 10px;
    }
    font-size: 10px;
    .remark-label {
      display: none;
    }
    .remark-sm {
      padding: 5px;
      background-color: rgb(241, 241, 241);
      margin-bottom: 5px;
      border-radius: 5px;
    }
    .remark-sm-label {
      display: inline;
    }
  }
}
.wallet-reacharge {
  min-height: 100vh;
  background-color: rgb(244, 244, 244);
  // padding-top: 30px;
  .user-wallet-recharge-heading {
    h3 {
      font-size: 20x;
      font-weight: 500;
    }
  }

  .sticky-top {
    top: 100px;
  }
  @media (max-width: 770px) {
    .recharge-item {
      font-size: 14px;
      .offer-item-field {
        span {
          font-size: 14px;
        }
        div {
          font-size: 16px;
        }
      }
    }
  }
}
#sippi-payment-page {
  background-color: rgb(242, 249, 251);
  min-height: 100vh;
  display: flex;
  padding: 40px 0;
  justify-content: center;
  .pay-payment-details-container {
    height: max-content;
    background-color: #ffffff;
    border-radius: 10px;
    width: 500px;
    @media (max-width: 576.98px) {
      width: 400px;
    }
    .image {
      height: 200px;
      width: 100%;
      border-radius: 10px 10px 0px 0px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: auto;
        // animation: infinite ease-in-out alternate-reverse 10s;
        // animation-name: move-image;
      }
      @media (max-width: 576.98px) {
        height: 200px;
      }
    }
    .payment-details {
      padding: 20px;
      @media (max-width: 576.98px) {
        padding: 10px;
      }
    }
    .details {
      margin-top: 20px;
      font-size: 14px;
      @media (max-width: 576.98px) {
        font-size: 13px;
      }
    }
  }
}

@keyframes move-image {
  from {
    transform: translateX(10px);
  }
  to {
    transform: translateX(-40px);
  }
}

.topup-items {
  .border-basic {
    border-top: 5px solid rgb(65, 65, 65);
  }
  .b-secondary {
    border-top: 5px solid rgb(51, 187, 51);
  }
  .b-premium {
    border-top: 5px solid rgb(246, 179, 9);
  }
  .recharge-item {
    padding: 10px 20px;
    background-color: #fff;
    position: relative;
    &:hover {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }

    // border-radius: 10px;

    img {
      width: auto;
      height: 50px;
    }
    ul {
      list-style: none;
      padding-left: 0px;
      li {
        font-size: 13px;
        margin-bottom: 6px;
        color: grey;
        i {
          color: rgb(0, 206, 0);
        }
      }
    }
    h3 {
      font-size: 20px;
      font-weight: 500;
      color: rgb(61, 61, 61);
    }
    .recomended-label {
      border-radius: 10px;
      position: absolute;
      top: -17px;
      left: 20px;
      padding: 4px 20px;
      font-size: 14px;
      font-weight: 600;
      background-color: rgb(255, 255, 173);
      // transform: translate(50%, 0);
    }
    .label {
      // background-color: rgb(91, 165, 255);
      // position: absolute;
      // width: 100%;
      // top: 0px;
      // right: 50%;
      // text-align: center;
      font-size: 22px;
      font-weight: 600;
      padding: 0px 0px 8px 0px;
      // margin-bottom: 10px;
      // transform: translate(50%, 0);
      color: rgb(91, 165, 255);
    }

    //

    .ribbon {
      width: 150px;
      height: 150px;
      overflow: hidden;
      position: absolute;
      top: -10px;
      right: -10px;
    }
    .ribbon__content {
      left: -25px;
      top: 30px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      display: block;
      width: 225px;
      padding: 10px 0;
      background-color: #0e8fd1;
      box-shadow: 0 0px 10px;
      color: #fff;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      text-transform: uppercase;
      text-align: center;
      // border: 2px dotted #fff;
      outline: 5px solid rgb(91, 165, 255);
    }
    .ribbon::before {
      top: 0;
      left: 0;
      border-top-color: transparent;
      border-right-color: transparent;
      position: absolute;
      z-index: -1;
      content: "";
      display: block;
      border: 5px solid rgb(43, 46, 49);
      box-sizing: content-box;
    }
    .ribbon::after {
      bottom: 0;
      right: 0;
      border-top-color: transparent;
      border-right-color: transparent;
      position: absolute;
      z-index: -1;
      content: "";
      display: block;
      border: 5px solid rgb(11, 12, 12);
    }
  }
}
