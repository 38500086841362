.partner-sippi-page-04 {
  .btn-outline-primary {
    font-size: 20px;
    font-weight: 500;
    border-color: #068ddd;
    color: #0e8fd1;
    border-radius: 8px;
    padding: 10px 20px 10px 20px;
  }
  .btn-outline-primary:hover {
    border-color: #068edd00;
    background: linear-gradient(93.66deg, #2791c0 0%, #59bbde 100%);
    color: #ffffff;
  }

  .btn-primary {
    font-size: 20px;
    font-weight: 500;
    border-radius: 10px;
    border: none;
    color: #ffffff;
    transition: transform 0.2s;
    background: linear-gradient(93.66deg, #2791c0 0%, #59bbde 100%);
    padding: 10px 20px 10px 20px;
  }
  .btn-outline-light {
    font-size: 20px;
    font-weight: 500;
    border-radius: 8px;
    color: #ffffff;
    border-color: #ffffff;
    padding: 10px 20px 10px 20px;
  }
  .btn-outline-light:hover {
    color: #068ddd;
    background-color: #ffffff;
  }
  .btn-primary:hover {
    border: none;
    transform: scale(0.8);
    background-color: #068ddd;
  }
  #home--front-section .btn-primary {
    border: none;
    background: linear-gradient(106.17deg, #44a7d5 0%, #12dec8 99.15%);
  }
  .demo-video .btn-primary {
    border: none;
    background: linear-gradient(106.17deg, #44a7d5 0%, #12dec8 99.15%);
  }
  #home--upcoming-features .btn-primary {
    border: none;
    background: linear-gradient(106.17deg, #44a7d5 0%, #12dec8 99.15%);
  }

  #partner-sippi-front .btn-primary {
    background: linear-gradient(93.66deg, #2791c0 0%, #59bbde 100%);
    border: none;
    color: #ffffff;
    @media (max-width: 575px) {
      margin-bottom: 10px;
    }
  }

  #tag-line {
    .btn-outline-primary {
      color: #068ddd;
      border-color: #068ddd;
    }
    .btn-outline-primary:hover {
      border: none;
      color: white;
      background: linear-gradient(93.66deg, #2791c0 0%, #59bbde 100%);
    }
  }

  .login-with-google-btn {
    padding: 12px 16px 12px 42px;
    border: none;
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(180, 180, 180, 0.7);
    border-radius: 10px;
    color: #757575;
    font-size: 14px;
    font-weight: 500;
    &:hover {
      box-shadow: 0px 0px 10px rgba(0, 123, 184, 0.78);
    }
  }

  .pricing {
    .btn-outline-primary {
      border: 1px solid #ffffff;
      color: #ffffff;
      border-radius: 30px;
      background-color: none;
    }
    .btn-primary {
      border-radius: 30px;
    }
  }
}
