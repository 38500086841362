#price-plan {
  background-image: linear-gradient(
      45deg,
      rgba(0, 8, 49, 0.679),
      rgba(255, 255, 255, 0)
    ),
    url("../../../../assets/images/ComboPlanBgImage/ITTeamBgImage.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 250px;
  margin-bottom: 50px;
  object-fit: cover;
  display: flex;
  align-items: center;
  background-position: center;

  @media (max-width: 767.98px) {
    height: 300px;
  }
}
.plan-points {
  padding: 20px 20px 10px 20px;
  background-color: rgb(237, 237, 237);
  list-style: none;
  li {
    display: flex;
    margin-bottom: 20px;
    i {
      margin-right: 10px;
    }
  }
}
